<template>
    <div class="about-page page-font">
      <!-- Banner -->
      <div class="banner">
        <Banner :bannerList="bannerList"
                :scale="0.5"/>
        <VisibleBox direction="bottom" style="position: relative; z-index: 2">
          <h2>About</h2>
        </VisibleBox>
      </div>

      <!-- 公司介绍 -->
       <AboutCompany />
      <!-- <div class="about-company main" style="padding: 30px 0">
        <VisibleBox>
          <Title title="about us"/>
          <AboutCompany />
        </VisibleBox>
      </div> -->

      <!-- 我们的优势 -->
      <div class="bg-light" id="partner">
        <div class="about-advantage main" style="padding: 30px 0 0 0">
          <Title title="OUR APPLICATIONS"/>
          <VisibleBox>
            <Advantage></Advantage>
          </VisibleBox>
        </div>
      </div>
    </div>
</template>

<script>
import Banner from '../components/Banner.vue'
import Title from '../components/Title.vue'
import VisibleBox from '../components/VisibleBox.vue'
import AboutCompany from '../components/AboutCompany.vue'
import Advantage from '../components/Advantage.vue'

export default {
  name: 'About',
  components: {
    Banner,
    Title,
    VisibleBox,
    AboutCompany,
    Advantage
  },
  props: {
    
  },
  data(){
    return {
      bannerList: [
        {imgUrl: require(`../assets/about/about-banner-01.jpg`)}
      ]
    }
  },
  methods: {
   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.about-page{

}
</style>
